import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "Set Yourself Up For Success Before Transitioning Out of the Military",
  "author": "Anthony",
  "date": "2018-02-09T06:26:52.000Z",
  "categories": ["Military Transition"],
  "slug": "set-yourself-up-for-success-before-transitioning-out-of-the-military",
  "draft": false,
  "meta_title": "Set Yourself Up For Success Before Transitioning Out of the Military"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Army soldiers are no stranger to preparedness and situational awareness. By the time most military personnel are transitioning out of the military, these skills have become second nature not only in relation to their military role but in everyday life as well. Yet somewhere along the line, we see many veterans leaving out these very formative skills that are not only essential but critical to a successful transition from active duty to the civilian sector.`}</p>
    <p><strong parentName="p">{`Preparedness`}</strong></p>
    <p>{`You can never start too early. The industry recommendation in beginning your transition is somewhere around a year out (and most military offered assistance programs start around then as well), but there are many avenues of preparedness that you can begin at any time in your career. First on the list of early preparedness: a resume. Your resume will become your story. But, that story has to be translated from the military jargon and technicals terms that strongly define your experience into a meaningful snapshot of how you will benefit a future employer. That future employer will not know what your MOS, military awards or training credentials mean to them as a civilian company. You have to be prepared and have that translation ready. So whether you’ve just enlisted, just finished the Captain’s Career Course, or you are reaching 25 years and counting down the days, start building your `}<a parentName="p" {...{
        "href": "http://www.purepost.co/"
      }}>{`translated resume`}</a>{` and allow that story to build with you.`}</p>
    <p><strong parentName="p">{`Situational Awareness`}</strong></p>
    <p>{`While your situation may no longer be in a combat zone, there are many layers of life, family, and future to work through as you strive to make the best decisions to sustain or even improve quality of life. A successful transition begins with staying actively engaged with the goal in sight. The transition experience is weighted with decision making, but when preparing early, there are a few basic parameters that you’ll need to be aware of and how your situation stands:`}</p>
    <ul>
      <li parentName="ul">{`Are you willing to relocate?`}</li>
      <li parentName="ul">{`Will you have children in school?`}</li>
      <li parentName="ul">{`What fields or industries will you pursue?`}</li>
      <li parentName="ul">{`Do you have a network to pull from? (If not, start here: `}<a parentName="li" {...{
          "href": "http://blog.purepost.co/3-steps-to-building-a-network/"
        }}>{`3 Steps to Building a Network`}</a>{`)`}</li>
      <li parentName="ul">{`What are you actually qualified to do in the civilian workforce?`}</li>
      <li parentName="ul">{`Can I articulate my military experience in a way a civilian employer will understand?`}</li>
    </ul>
    <p>{`If you start to ask these ‘ veteran in transition’ questions early and engage in conversations with mentors and spouses, the framework to your transition will slowly begin to build. If you have a strong framework and concept of the direction you desire to go BEFORE you hit those mandated military transition programs, you’ll be able to better piece together the picture being offered for life after active military service.`}</p>
    <p>{`Active, self-guided involvement in early preparedness is key to a successful transition. Don’t wait until someone says, “Your first TAPS workshop is next Monday.” Go into that transitional period with a plan in place. Remain knowledgeable about your options, clear on the storytelling of your military experience with a translated resume, and aware of the parameters you’ll be needing to set, meet, and achieve.`}</p>
    <p>{`For more career-driven preparedness tips, check out this blog post!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      